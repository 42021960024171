export const snackbarError: any = {
  duration: 3000,
  verticalPosition: 'top',
  horizontalPosition: 'end',
  panelClass: ['snack-error'],
};

export const snackbarSuccess: any = {
  duration: 3000,
  verticalPosition: 'top',
  horizontalPosition: 'end',
  panelClass: ['snack-success'],
};
