import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { PaginatorDirective } from '../directives/pagination.directive';

@NgModule({
  declarations: [PaginatorDirective],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule
  ], exports: [
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    PaginatorDirective
  ]
})
export class SharedModuleModule { }
