import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-error403',
  templateUrl: './error403.component.html',
  styleUrls: ['./error403.component.scss']
})
export class Error403Component implements OnInit {
  constructor(
    private router: Router
  ) { }

  ngOnInit() { }
  redirectToFirstAllowedRoute() {
    if (!localStorage.getItem('accessManagementRoles')) {
      this.router.navigate(['/login']);
    }
    let accessManagementRoles = localStorage.getItem('accessManagementRoles') || '';
    if (accessManagementRoles.includes('super-admin') || accessManagementRoles.includes('developer') ||
      accessManagementRoles.includes('onboarding-and-cs') || accessManagementRoles.includes('customer-success')) {
      this.router.navigate(['/brands']);
    } else if (accessManagementRoles.includes('marketing')) {
      this.router.navigate(['/coupons']);
    } else if (accessManagementRoles.includes('finance')) {
      this.router.navigate(['/payouts']);
    } else if (accessManagementRoles.includes('sales')) {
      this.router.navigate(['/free-trial-requests']);
    } else {
      this.router.navigate(['/']);
    }
  }
}