import { Component } from '@angular/core';
import { LoadingService } from 'src/app/services/loading.service';
import { LoginService } from './services/login.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  title = 'nugttah-admin';
  userLogedIn = false;
  loading$ = this.loader.loading$;
  constructor(private loginService: LoginService,


    public loader: LoadingService
  ) {
    this.userLogedIn = this.loginService.isAuthenticated();
    if (this.userLogedIn) {
      this.loginService.viewAdmin(this.loginService.getAdminId()).subscribe((resp: any) => {
        localStorage.setItem('accessManagementRoles', JSON.stringify(resp.data.accessManagementRoles));
      });
    }
  }

  onActivate(event: any) {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

}
