import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {
  constructor(
    private router: Router,
    private loginService: LoginService
  ) {

  }
  ngOnInit(): void {
    this.redirectToFirstallowedRoute();
  }
  async redirectToFirstallowedRoute() {
    const resp = await this.loginService.viewAdmin(this.loginService.getAdminId()).toPromise();
    const currentAdminAccessManagementRoles = resp.data.accessManagementRoles;
    localStorage.setItem('accessManagementRoles', JSON.stringify(resp.data.accessManagementRoles));
    if (currentAdminAccessManagementRoles.includes('super-admin') || currentAdminAccessManagementRoles.includes('developer')) {
      this.router.navigate(['/brands']);
    } else if (currentAdminAccessManagementRoles.includes('marketing')) {
      this.router.navigate(['/coupons']);
    } else if (currentAdminAccessManagementRoles.includes('customer-success')) {
      this.router.navigate(['/brands']);
    } else if (currentAdminAccessManagementRoles.includes('onboarding-and-cs')) {
      this.router.navigate(['/brands']);
    } else if (currentAdminAccessManagementRoles.includes('finance')) {
      this.router.navigate(['/payouts']);
    } else if (currentAdminAccessManagementRoles.includes('sales')) {
      this.router.navigate(['/free-trial-requests']);
    } else {
      this.router.navigate(['/']);
    }
  }
}
