import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { LoginService } from '../services/login.service';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  isLoggedIn = false;
  constructor(private loginService: LoginService,
    private router: Router) { }

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean | UrlTree> {

    this.isLoggedIn = this.loginService.isAuthenticated();
    if (!this.isLoggedIn) {
      this.router.navigate(['/login']);
      return false;
    }
    let allowedRoles = next.data.roles as Array<string>;
    if (!allowedRoles && next.children && next.children.length > 0) {
      next.children.forEach(child => {
        const currentRoute = state.url;
        const lastPart = currentRoute.split('/').pop();
        const current: any = child.routeConfig?.children && child.routeConfig?.children.filter((route: any) =>
          lastPart === route.path || route.data?.roles
        )[0];
        allowedRoles = current.data.roles as Array<string>;
      });
    }

    if (await this.loginService.hasAnyRole(allowedRoles)) {
      return true;
    } else {
      this.router.navigate(['/403']);
      return false;
    }
  }


}




