import { InjectionToken } from '@angular/core';
import { snackbarError , snackbarSuccess } from './snackbar';


export const constants = {
  snackbarError,
  snackbarSuccess
};

export type Constants = typeof constants;

export const CONSTANTS = new InjectionToken<Constants>('constants');
