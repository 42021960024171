
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AnonymousGuard } from './guard/anonymous.guard';
import { AuthGuard } from './guard/auth.guard';
import { Error403Component } from './pages/error403/error403.component';
import { LoadingComponent } from './pages/loading/loading.component';
import { LoginService } from './services/login.service';

const routes: Routes = [];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
  constructor(
    private loginService: LoginService
  ) {
    routes.push(
      {
        path: '',
        redirectTo: this.redirectToFirstallowedRoute(),
        pathMatch: 'full',
        canActivate: [AuthGuard]
      },
      {
        path: 'users',
        loadChildren: () =>
          import('src/app/pages/users/users.module').then(m => m.UsersModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'login',
        loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule),
        canActivate: [AnonymousGuard]
      },
      {
        path: 'brands',
        loadChildren: () =>
          import('./pages/brands/brands.module').then(m => m.BrandsModule),
        canActivate: [AuthGuard],
        data: {
          roles: ['super-admin', 'developer', 'customer-success', 'onboarding-and-cs', 'finance']
        }
      },
      {
        path: 'payouts',
        loadChildren: () =>
          import('./pages/payouts/payouts.module').then(m => m.PayoutsModule),
        canActivate: [AuthGuard],
        data: {
          roles: ['super-admin', 'finance']
        }
      },
      {
        path: 'loyalty/shared-points',
        loadChildren: () =>
          import('./pages/shared-points/shared-points.module').then(m => m.SharedPointsModule),
        canActivate: [AuthGuard],
        data: {
          roles: ['super-admin', 'developer']
        }
      },
      {
        path: 'gifts',
        loadChildren: () =>
          import('./pages/gift-vouchers/gift-vouchers.module').then(m => m.GiftVouchersModule),
        canActivate: [AuthGuard],
        data: {
          roles: ['super-admin', 'developer']
        }
      },
      {
        path: 'gift-card',
        loadChildren: () =>
          import('./pages/gift-card/gift-card.module').then(m => m.GiftCardModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'notifications',
        loadChildren: () =>
          import('./pages/notifications/notifications.module').then(m => m.NotificationsModule),
        canActivate: [AuthGuard],
        data: {
          roles: ['super-admin', 'developer']
        }
      },
      {
        path: 'customers-messaging',
        loadChildren: () =>
          import('./pages/customers-messaging/customers-messaging.module').then(m => m.CustomersMessagingModule),
        canActivate: [AuthGuard],
        data: {
          roles: ['super-admin', 'developer']
        }
      },
      {
        path: 'csv-reports',
        loadChildren: () =>
          import('./pages/csv-reports/csv-reports.module').then(m => m.CsvReportsModule),
        canActivate: [AuthGuard],
        data: {
          roles: ['super-admin', 'developer']
        }
      },
      {
        path: 'reports',
        loadChildren: () =>
          import('./pages/reports/reports.module').then(m => m.ReportsModule),
        canActivate: [AuthGuard],
        data: {
          roles: ['super-admin', 'developer']
        }
      },
      {
        path: 'market-categories',
        loadChildren: () =>
          import('./pages/market-categories/market-categories.module').then(m => m.MarketCategoriesModule),
        canActivate: [AuthGuard],
        data: {
          roles: ['super-admin', 'developer', 'marketing']
        }
      },
      {
        path: 'scheduled-smses',
        loadChildren: () =>
          import('./pages/scheduled-smses/scheduled-smses.module').then(m => m.ScheduledSmsesModule),
        canActivate: [AuthGuard],
        data: {
          roles: ['super-admin']
        }
      },
      {
        path: 'customers',
        loadChildren: () =>
          import('./pages/customers-new/customers-new.module').then(m => m.CustomersNewModule),
        canActivate: [AuthGuard],
        data: {
          roles: ['super-admin', 'developer', 'onboarding-and-cs', 'customer-success']
        }
      },
      {
        path: 'orders',
        loadChildren: () =>
          import('./pages/orders/orders.module').then(m => m.OrdersModule),
        canActivate: [AuthGuard],
        data: {
          roles: ['super-admin', 'developer', 'onboarding-and-cs']
        }
      },
      {
        path: 'pending-online-orders',
        loadChildren: () =>
          import('./pages/pending-online-orders/pending-online-orders.module').then(m => m.PendingOnlineOrdersModule),
        canActivate: [AuthGuard],
        data: {
          roles: ['super-admin', 'developer', 'onboarding-and-cs']
        }
      },
      {
        path: 'coupons',
        loadChildren: () =>
          import('./pages/coupons/coupons.module').then(m => m.CouponsModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'free-trial-requests',
        loadChildren: () =>
          import('./pages/free-trial-requests/free-trial-requests.module').then(m => m.FreeTrialRequestsModule),
        canActivate: [AuthGuard],
        data: {
          roles: ['super-admin', 'developer', 'sales']
        }
      },
      {
        path: 'cashback',
        loadChildren: () =>
          import('./pages/cashback/cashback.module').then(m => m.CashbackModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'scheduled-notifications',
        loadChildren: () =>
          import('./pages/scheduled-notifications/scheduled-notifications.module').then(m => m.ScheduledNotificationsModule),
        canActivate: [AuthGuard],
        data: {
          roles: ['super-admin', 'developer', 'marketing']
        }
      },
      {
        path: 'settings',
        loadChildren: () =>
          import('./pages/settings/settings.module').then(m => m.SettingsModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'branch-categories',
        loadChildren: () =>
          import('./pages/branch-categories/branch-categories.module').then(m => m.BranchCategoriesModule),
        canActivate: [AuthGuard],
        data: {
          roles: ['super-admin', 'developer', 'marketing']
        }
      },
      {
        path: 'referral-programs',
        loadChildren: () =>
          import('./pages/referral-programs/referral-programs.module').then(m => m.ReferralProgramsModule),
        canActivate: [AuthGuard],
        data: {
          roles: ['super-admin', 'developer', 'marketing']
        }
      },
      {
        path: 'deep-links',
        loadChildren: () =>
          import('./pages/deep-links/deep-links.module').then(m => m.DeepLinksModule),
        canActivate: [AuthGuard],
        data: {
          roles: ['super-admin', 'developer', 'marketing']
        }
      },
      {
        path: 'branch-subscription',
        loadChildren: () =>
          import('./pages/branch-subscription/branch-subscription.module').then(m => m.BranchSubscriptionModule),
        canActivate: [AuthGuard],
        data: {
          roles: ['super-admin', 'developer', 'customer-success']
        }
      },
      {
        path: 'bogo',
        loadChildren: () =>
          import('./pages/bogo/bogo.module').then(m => m.BogoModule),
        canActivate: [AuthGuard],
        data: {
          roles: ['super-admin', 'developer', 'marketing']
        }
      },
      { path: 'loading', component: LoadingComponent },
      { path: '**', component: Error403Component },
      { path: '403', component: Error403Component });
  }
  redirectToFirstallowedRoute() {
    if (localStorage.getItem('accessManagementRoles') === null) {
      const userLogedIn = this.loginService.isAuthenticated();
      return userLogedIn ? 'loading' : 'login';
    }
    let accessManagementRoles = localStorage.getItem('accessManagementRoles') || '';
    const roles = JSON.parse(accessManagementRoles);
    if (roles.includes('super-admin') || roles.includes('developer') || roles.includes('marketing') ||
      roles.includes('customer-success') || roles.includes('onboarding-and-cs')) {
      return 'brands'
    } else if (roles.includes('finance')) {
      return 'payouts'
    } else if (roles.includes('sales')) {
      return 'free-trial-requests'
    } else {
      return ''
    }
  }
}
