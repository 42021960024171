import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements OnInit {
  @Output() closeSide = new EventEmitter();

  userType: any;
  currentAdminAccessManagementRoles: Array<string> = [];
  constructor(
    private loginService: LoginService
  ) { }
  async ngOnInit() {
    if (localStorage.getItem('userRole')) {
      this.userType = localStorage.getItem('userRole');
    }
    const resp = await this.loginService.viewAdmin(this.loginService.getAdminId()).toPromise();
    this.currentAdminAccessManagementRoles = resp.data.accessManagementRoles;

  }
  checkRole(allowedRoles: any): boolean {
    if (this.currentAdminAccessManagementRoles.length && allowedRoles.some(role => this.currentAdminAccessManagementRoles.includes(role))) {
      return true;
    }
    return false;
  }
  closeMenu() {
    this.closeSide.emit();
  }
}
