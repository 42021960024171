import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CONSTANTS, Constants } from 'src/app/constants/constants';
import { versions } from '../../environments/versions';

@Injectable()
export class mainInterceptor implements HttpInterceptor {

  constructor(
    private _snackBar: MatSnackBar,
    @Inject(CONSTANTS) public constants: Constants
  ) { }

  userToken: any;
  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    this.userToken = localStorage.getItem('userData');
    const Authorization = `Bearer ${this.userToken}`;
    return next.handle(
      httpRequest.clone({
        setHeaders: {
          Authorization,
          'Nugttah-App-Name': 'admin-panel',
          'Nugttah-App-Version': versions.version,
        }
      })
    ).pipe(catchError((error) => this.errorHandler(error)));
  }

  // Customize the default error handler here if needed
  private errorHandler(response: HttpEvent<any>): Observable<HttpEvent<any>> {

    if (response instanceof HttpErrorResponse) {

      if (response.status != 200 && response.status != 204) {
        this._snackBar.open(response?.error?.message?.en, '', this.constants.snackbarError);
      }

    }


    throw response;
  }


}
