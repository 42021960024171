import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { LoginService } from 'src/app/services/login.service';
import { Subscription } from "rxjs";
import { Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  form: any;
  subscription: Subscription = new Subscription();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private loginService: LoginService,
    private router: Router,
    private appComponent: AppComponent
  ) { }

  ngOnInit(): void {
    this.initializeForm();
  }

  initializeForm() {
    this.form = this.formBuilder.group({
      user_name: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      user_pass: ['', [Validators.required]],
    });
  }

  submit() {
    this.loginService.loginServ(
      {
        email: this.form.controls.user_name.value,
        password: this.form.controls.user_pass.value
      }
    ).subscribe((result) => {
      this.appComponent.userLogedIn = true;
      localStorage.setItem("userData", result.data.accessToken);
      localStorage.setItem("userRole", result.data.role);
      this.loginService.login();
      this.redirectToFirstallowedRoute();
    })

  }
  async redirectToFirstallowedRoute() {
    const resp = await this.loginService.viewAdmin(this.loginService.getAdminId()).toPromise();
    const currentAdminAccessManagementRoles = resp.data.accessManagementRoles;
    if (currentAdminAccessManagementRoles.includes('super-admin') || currentAdminAccessManagementRoles.includes('developer')) {
      this.router.navigate(['/brands']);
    } else if (currentAdminAccessManagementRoles.includes('marketing')) {
      this.router.navigate(['/coupons']);
    } else if (currentAdminAccessManagementRoles.includes('customer-success')) {
      this.router.navigate(['/brands']);
    } else if (currentAdminAccessManagementRoles.includes('onboarding-and-cs')) {
      this.router.navigate(['/brands']);
    } else if (currentAdminAccessManagementRoles.includes('finance')) {
      this.router.navigate(['/payouts']);
    } else if (currentAdminAccessManagementRoles.includes('sales')) {
      this.router.navigate(['/free-trial-requests']);
    } else {
      this.router.navigate(['/']);
    }
  }
}
