import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import * as jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  baseApiURL: any = `${environment.API_URL}`;
  authenticationState = new BehaviorSubject(false);
  isLoggedIn = false;

  constructor(
    private http: HttpClient
  ) { }

  loginServ(data: any): Observable<any> {
    return this.http
      .post<any>(
        `${this.baseApiURL}/admins/login`,
        data
      )
      .pipe((resp: any) => resp);
  }


  login() {
    this.authenticationState.next(true);
    this.isLoggedIn = true;
  }

  logout() {
    this.authenticationState.next(false);
    this.isLoggedIn = false;
    localStorage.setItem('userData', '');
    localStorage.removeItem('accessManagementRoles');
  }

  isAuthenticated() {
    if (localStorage.getItem('userData')) {
      this.authenticationState.next(true);
      this.isLoggedIn = true;
    } else {
      this.authenticationState.next(false);
      this.isLoggedIn = false;
    }
    return this.authenticationState.value;
  }
  getAdminId() {
    const token = localStorage.getItem('userData') || '{}';
    const decoded: any = jwt_decode.jwtDecode(token);
    return decoded.id;
  }

  viewAdmin(id: string): Observable<any> {
    return this.http.get<any>(`${this.baseApiURL}/admins/${id}`).pipe((resp: any) => resp);
  }
  async hasAnyRole(allowedRoles: Array<string>) {
    let accessManagementRoles = localStorage.getItem('accessManagementRoles') || '';
    if (!accessManagementRoles) {
      const resp = await this.viewAdmin(this.getAdminId()).toPromise();
      localStorage.setItem('accessManagementRoles', JSON.stringify(resp.data.accessManagementRoles));
      accessManagementRoles = JSON.stringify(resp.data.accessManagementRoles);
    }
    const roles = JSON.parse(accessManagementRoles);
    return allowedRoles && allowedRoles.some(role => roles?.includes(role));
  }
}
