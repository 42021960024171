import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { LoginService } from 'src/app/services/login.service';
import { AppComponent } from 'src/app/app.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {
  @Output() openSidePanel = new EventEmitter();
  constructor(
    private loginService: LoginService,
    private appComponent: AppComponent,
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  openSideMenu() {
    document.body.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    this.openSidePanel.emit();
  }

  logout(){
    this.loginService.logout();
    this.appComponent.userLogedIn = false ;
     this.router.navigate(['/login']);
  }

}
